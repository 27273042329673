<template>
  <div class="col s12 center white z-depth-2 dispute" @click="goToDispute">
    <h6 class="orange white-text left-align title">#ID: {{ dispute._id}}</h6>
    
    <h6 class = "orange-text left-align">
      Status: <span class="grey-text text-darken-2">{{ dispute.open }}</span>
    </h6>
    
    <h6 class = "orange-text left-align">
      Abierto Por: <span class="grey-text text-darken-2">{{ dispute.actor }}</span>
    </h6>
    
    <h6 class = "orange-text left-align">
      Message:
    </h6>
    <p class = "grey-text text-darken-2 truncate left-align">{{ dispute.message }}</p>
  
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  props: ['dispute'],
  setup(props){
    const router = useRouter();
    const goToDispute = () => router.push({name: "ResolveDispute", params: {id: props.dispute._id}})
    return { goToDispute };
  }
}
</script>

<style scoped>
  .dispute{
    margin-top: 20px;
  }

  .title{
    padding: 5px;
  }
</style>